/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from 'react';
import styled from 'styled-components';

import LogoSVG from '../images/logo.svg';

const Wrapper = styled.header`
  position: relative;
  width: 1454px;
  max-width: calc(100% - 80px);
  height: 64px;
  display: flex;
  align-items: center;

  margin-left: auto;
  margin-right: auto;

  svg {
    height: 28px;
  }
`;

const Label = styled.label`
  margin-top: 8px;
  margin-left: 10px;
  white-space: nowrap;
  font-size: 14px;
  color: #697b8c;
  font-weight: 500;
  transition: all 0.3s;
`;

const Header: React.FC<any> = () => {
  return (
    <Wrapper>
      <LogoSVG />
      <Label>Developer Portal</Label>
    </Wrapper>
  );
};

export default Header;
