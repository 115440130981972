/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import { useMedia } from 'react-use';
import { MenuOutlined } from '@ant-design/icons';
import { Select } from 'antd';

import { Account } from './Account';
import { NavMenuItems, Nav } from './NavMenuItems';

import Logo from '../images/logo.svg';

import styles from './Header.module.less';

const { Option } = Select;

interface HeaderProps {
  rootDomain: string;
  serviceApiUrl: string;
  path?: string;
  subTitle?: React.ReactNode;
  subTitleHref?: string;
  navs?: Nav[];
  showSearch?: boolean;
  defaultLanguage?: 'en';
  transparent?: boolean;
  isHomePage?: boolean;
  versions?: { [key: string]: string };
}

const Header: React.FC<HeaderProps> = ({
  subTitle = '',
  path = '',
  navs = [],
  transparent,
  isHomePage,
  rootDomain,
  serviceApiUrl,
  versions,
}) => {
  const [productMenuVisible] = useState(false);
  const [popupMenuVisible, setPopupMenuVisible] = useState(false);
  const onTogglePopupMenuVisible = () => {
    setPopupMenuVisible(!popupMenuVisible);
  };

  useEffect(() => {
    if (popupMenuVisible) {
      setPopupMenuVisible(false);
    }
  }, [path]);

  useEffect(() => {
    if (popupMenuVisible) {
      document.documentElement!.style.overflow = 'hidden';
    } else {
      document.documentElement!.style.overflow = '';
    }
    return () => {
      document.documentElement!.style.overflow = '';
    };
  }, [popupMenuVisible]);

  const isWide = useMedia('(min-width: 767.99px)', true);
  const menuIcon = !isWide ? (
    <MenuOutlined
      className={styles.menuIcon}
      onClick={onTogglePopupMenuVisible}
    />
  ) : null;

  const menu = (
    <ul
      className={classNames(styles.menu, {
        [styles.popup]: !isWide,
        [styles.popupHidden]: !popupMenuVisible,
      })}
    >
      {navs && navs.length ? <NavMenuItems navs={navs} path={path} /> : null}
      {versions && Object.keys(versions || {}).length > 0 ? (
        <li>
          <Select
            defaultValue={Object.keys(versions)[0]}
            className={styles.versions}
            bordered={false}
            size="small"
            onChange={(value: string) => {
              window.location.href = value;
            }}
          >
            {Object.keys(versions).map((version: string) => {
              const url = versions[version];
              if (url.startsWith('http')) {
                return (
                  <Option key={url} value={url}>
                    {version}
                  </Option>
                );
              }
              return null;
            })}
          </Select>
        </li>
      ) : null}
      <li>
        <Account
          label="Account"
          language="en"
          rootDomain={rootDomain}
          serviceApiUrl={serviceApiUrl}
        />
      </li>
    </ul>
  );

  return (
    <header
      className={classNames(styles.header, {
        [styles.transparent]: !!transparent && !productMenuVisible,
        [styles.isHomePage]: !!isHomePage,
        [styles.fixed]: popupMenuVisible,
      })}
    >
      <div className={styles.container}>
        <div className={styles.left}>
          <h1>
            <a href={`${rootDomain}/welcome`}>
              <Logo />
            </a>
          </h1>
          {subTitle && (
            <>
              <h2 className={styles.subProduceName}>{subTitle}</h2>
            </>
          )}
        </div>
        <nav className={styles.nav}>
          {menu}
          {menuIcon}
        </nav>
      </div>
    </header>
  );
};

export default Header;
