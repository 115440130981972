import { CSSProperties } from 'styled-components';
import * as React from 'react';

export interface TermsAndConditionsContentProps {
  style?: CSSProperties;
}

export const TermsAndConditionsContent = ({
  style,
}: TermsAndConditionsContentProps) => {
  return (
    <div style={style || { maxHeight: '55vh', overflow: 'scroll' }}>
      <h3>Version: 2018.1 - March 2018</h3>
      <h2>Cookies</h2>
      <h3>What are cookies</h3>
      <p>
        Cookies are small pieces of text sent by your web browser by a website
        you visit. A cookie file isstored in your web browser and allows the
        Service or a third­party to recognize you and make yournext visit easier
        and the Service more useful to you.Cookies can be persistent or session
        cookies.
      </p>
      <h3>How we are using cookies</h3>
      <p>
        When you use and access the Service, we may place a number of cookies
        files in your webbrowser. We use cookies to enable better user
        experience in order to allow automatic sign on based on a stored token.
      </p>
      <p>
        This agreement is between you or the entity you represent and Secure
        Payment Technologies GmbH (Blue Code). If you specify a company name in
        connection with signing up for or ordering a Service, you have entered
        into this Agreement on behalf of that organization or company.
      </p>
      <h2>Services</h2>
      <h3>Right to use</h3>
      <p>
        We grant you the right to access and use the Services in accordance with
        this Agreement.
      </p>
      <h3>Manner of use</h3>
      <p>
        You may not: reverse engineer, decompile, disassemble or work around
        technical limitations in the Services; disable, tamper with or otherwise
        attempt to circumvent any mechanism that limits your use of the
        Services; rent, lease, lend, resell, transfer, or sublicense any
        Services or portion thereof to or for third parties, except as
        explicitly permitted herein or in license terms that accompany any
        Services component; use the Services for any purpose that is unlawful or
        prohibited by this Agreement; or use the Services in any manner that
        could damage, disable, overburden, or impair any Blue Code Blue
        Codeservice, or the network(s) connected to any Blue CodeBlue Code
        service, or interfere with any other party’s use and enjoyment of any
        Services.
      </p>
      <h3>Updates</h3>
      <p>
        We may make changes to the Services from time to time, including: the
        availability of features; how long, how much or how often any given
        feature may be used; and feature dependencies upon other services or
        software. “Blue Code Software” means software and computer code,
        including sample code and Developer Services Software.
      </p>

      <h3>Preview features</h3>
      <p>
        We may make features available on a Preview basis. Previews may be
        subject to reduced or different security, compliance, privacy,
        availability, reliability, and support commitments, as further explained
        in the Privacy Statement, and any additional notices provided with the
        Preview. We may change or discontinue Previews at any time without
        notice. We also may choose not to release a Preview into “General
        Availability”.
      </p>
      <h2>Software</h2>
      <h3>Using Blue Code Software outside the Service</h3>
      <p>
        Blue Code may provide you with Blue Code Software through or as a part
        of the Developer Services. Termination of use of or access to the
        Developer Services or the termination of this Agreement terminates your
        right to possess or use any such Blue Code Software; Blue Code may
        provide you with Blue Code Software for use outside the Developer
        Services and with (1) the Developer Services or (2) programs you develop
        using the Developer Services. Scope of rights. All Blue Code Software
        are the copyrighted works of Blue Code or its suppliers. All Blue Code
        Software are licensed not sold and may not be transferred unless
        specified otherwise in any license terms provided with the Blue Code
        Software. Rights to access Blue Code Software on any device do not give
        you any right to implement Blue Code patents or other Blue Code
        intellectual property in software or devices that access that device.
      </p>
      <h3>Third party software</h3>
      <p>
        You are solely responsible for any third party software that you
        install, connect, or use with any Service.
      </p>

      <h3>Open source software as part of the Service</h3>
      <p>
        If the Service uses or distributes any third party software with open
        source software license terms (“Open Source”), then such Open Source is
        licensed to you by Blue Code solely to allow you to interact with the
        Service under terms of this Agreement.
      </p>

      <h3>Application programming interfaces</h3>
      <p>
        Blue Code will not assert any of its patent rights on account of your
        products calling application programming interfaces that it publishes on
        the Documentation Portals (“APIs”) in order to receive services from the
        Blue Code product that exposes the APIs.
      </p>
      <h3>Bluecode Content</h3>
      <p>
        All Blue Code Content is the copyrighted work of Blue Code or its
        suppliers and is governed by the terms of this agreement. “Content”
        means documents, photographs, videos, and other graphical, textual, or
        audio-visual content that may be subject to copyright protection.
      </p>

      <h3>Integration Process</h3>
      <p>
        The integration process includes testing procedures where your
        collaboration is required. Integration projects end with the
        certification of the integration project. You are not allowed to deploy
        the integrated services before the certification has been approved by
        BCI.
      </p>

      <h2>Security, privacy, and Customer Data</h2>

      <h3>Security</h3>
      <p>
        We maintain technical and organizational measures, internal controls,
        and data security routines intended to protect Customer Data against
        accidental loss or change, unauthorized disclosure or access, or
        unlawful destruction. “Customer Data” means any content or other data,
        including all text, sound, software, or image files that are provided to
        us by, or on behalf of, you through your use of the Developer Services
        for use by you or your authorized users.
      </p>
      <h3>Privacy and data location</h3>
      <p>
        We treat Customer Data in accordance with the terms herein. We may
        transfer to, store, and process Customer Data in any country where we
        use Developer Services. You will obtain any necessary consent or rights
        from end users or others whose data or personal information or other
        data you will be hosting in the Services.
      </p>
      <h3>Use of Customer Data</h3>
      <p>
        We will use Customer Data to provide the Services. This use may include
        troubleshooting to prevent, find and fix problems with the operation of
        the Services and ensuring compliance with this Agreement.
        Subcontractors. We may hire other companies to provide limited services
        on our behalf, such as customer support.
      </p>
      <h2>
        Customer accounts, customer conduct, identity services, and feedback
      </h2>

      <h3>Account creation</h3>
      <p>
        If any of the Services requires you to open an account, you must
        complete the registration process by providing us with current, complete
        and accurate information. You may not select an account user name or
        identifier that impersonates someone else, is or may be illegal, or may
        be protected by trademark or other proprietary rights, is vulgar or
        offensive or may cause confusion. We reserve the right to reject and/or
        reassign these user names and Service identifiers in our sole
        discretion.
      </p>
      <h3>Responsibility for your accounts</h3>
      <p>
        You are responsible for: any and all activities that occur under your
        account; maintaining the confidentiality of any non-public
        authentication credentials associated with your use of the Services; and
        promptly notifying our customer support team about any possible misuse
        of your accounts or authentication credentials, or any security incident
        related to the Services.
      </p>
      <h3>Identity usage across Services</h3>
      <p>
        We may provide Services that supplement Blue Code Software and rely upon
        your user account or other identity mechanism. We may use this
        information to identify you and authorize access to Blue Code Content,
        Blue Code Software, and other resources across the Services.
      </p>
      <h3>Services accessible only to invited customers</h3>
      <p>
        Elements of the Services may be accessible to you on an invitation only
        basis, for example as part of a program for using pre-release Services
        and providing feedback to us. Those Services are confidential
        information of Blue Code. You may not disclose this confidential
        information to any third party for a period of five years. This
        restriction does not apply to any information that is or becomes
        publicly available without a breach of this restriction, was lawfully
        known to the receiver of the information without an obligation to keep
        it confidential, is received from another source who can disclose it
        lawfully and without an obligation to keep it confidential, or is
        independently developed. You may disclose this confidential information
        if required to comply with a court order or other government demand that
        has the force of law. Before doing so, you must seek the highest level
        of protection available and, when possible, give us enough prior notice
        to provide a reasonable chance to seek a protective order.
      </p>

      <h2>Term, termination, and suspension</h2>

      <h3>Agreement Term and termination</h3>
      <p>You may terminate this Agreement at any time.</p>

      <h3>Regulatory</h3>
      <p>
        In any country where any current or future government regulation or
        requirement that applies to us presents a hardship to us operating the
        Services without change, and/or causes us to believe this Agreement or
        the Services may be in conflict with any such regulation or requirement,
        we may change the Services or terminate the Agreement. Your sole remedy
        for such changes to the Services under this Section is to terminate this
        Agreement.
      </p>
      <h3>Suspension</h3>
      <p>
        We may suspend your use of the Services if: (1) reasonably needed to
        prevent unauthorized access to Customer Data; (2) you fail to respond to
        a claim of alleged infringement within a reasonable time; or (3) you
        violate this Agreement. We will attempt to suspend access to the minimum
        necessary part of the Services while the condition or need exists.
        Termination for non-usage. We may suspend or terminate a Service account
        after a prolonged period of inactivity. For Developer Services, if you
        have a free account we may terminate this Agreement and/or delete any
        Customer Data automatically generated during the Developer Services sign
        up process if you fail to upload or create any Customer Data within 90
        days of your initial provisioning of the Developer Service.
      </p>
      <h3>Termination of Access to Documentation Portals</h3>
      <p>
        We reserve the right to terminate your access to the Documentation
        Portals at any time, without notice, for any reason whatsoever.
      </p>

      <h2>Warranties</h2>
      <p>
        BLUE CODE AND ITS RESPECTIVE SUPPLIERS PROVIDE THE SERVICES (INCLUDING
        THE CONTENT AND APIS) “AS IS,” “WITH ALL FAULTS” AND “AS AVAILABLE.” YOU
        BEAR THE RISK OF USING IT. WE PROVIDE NO WARRANTIES, GUARANTEES OR
        CONDITIONS, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING
        WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
        NON-INFRINGEMENT. YOU MAY HAVE ADDITIONAL RIGHTS UNDER YOUR LOCAL LAWS
        WHICH THIS AGREEMENT CANNOT CHANGE. THESE DISCLAIMERS WILL APPLY TO THE
        FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, INCLUDING APPLICATION TO
        THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
        PURPOSE AND NON-INFRINGEMENT.
      </p>
      <h2>Limitation of liability</h2>

      <h3>Limitation</h3>
      <p>
        The aggregate liability of each party under this Agreement is limited to
        direct damages up to the amount paid under this Agreement for the
        Developer Services giving rise to that liability during the 12 months
        before the liability arose, or for Services provided free of charge,
        Five Hundred Euro (500.00 €).
      </p>
      <h3>EXCLUSION</h3>
      <p>
        NEITHER PARTY, NOR ITS SUPPLIERS WILL BE LIABLE FOR LOSS OF REVENUE,
        LOST PROFITS, OR INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, PUNITIVE,
        OR EXEMPLARY DAMAGES, EVEN IF THE PARTY KNEW THEY WERE POSSIBLE.
      </p>
      <h2>Miscellaneous</h2>

      <h3>No additional rights granted</h3>
      <p>
        We reserve all rights not expressly granted under this agreement, and no
        other rights are granted under this agreement by implication or estoppel
        or otherwise.
      </p>
      <h3>Notices</h3>
      <p>
        You agree to receive electronic notices from us related to the Services,
        which will be sent by email to your specified end user or administrator
        contact information or presented to you in the Service experience.
        Notices are effective on the date on the return receipt for mail, the
        date sent for email, and the date presented if within the Service
        experience.
      </p>
      <h3>Assignment</h3>
      <p>You may not assign this agreement either in whole or in part.</p>
      <h3>Severability</h3>
      <p>
        If any part of this agreement is held unenforceable, the rest remains in
        full force and effect.
      </p>

      <h3>Waiver</h3>
      <p>
        Failure to enforce any provision of this agreement will not constitute a
        waiver.
      </p>

      <h3>No agency</h3>
      <p>
        We are independent contractors. This agreement does not create an
        agency, partnership or joint venture.
      </p>

      <h3>Applicable law and venue</h3>
      <p>This agreement is governed by the laws of Austria</p>

      <h3>Entire agreement</h3>
      <p>
        This agreement is the entire agreement concerning its subject matter and
        supersedes any prior or concurrent communications.
      </p>
      <h3>
        Modifications. We may modify this agreement at any time with or without
        individual notice to you by posting a revised version on the legal
        information section of the Developer Services.
      </h3>
      <h4>
        COPYRIGHT NOTICE | ©2020 Bluecode International AG. All rights reserved
        | St. Gallerstrasse 18, CH-8853 Lachen
      </h4>
    </div>
  );
};
