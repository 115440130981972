import * as React from 'react';
import styled from 'styled-components';

import { AccountProvider } from 'bc-providers';

import { IFormProps, FormIds } from './Form';
import { Form, Input, Button, Descriptions } from 'antd';
import { isResponseError, handleError } from '../utils';
import { showSuccessNotifcation } from './Notification';

const Wrapper = styled.div`
  max-width: 300px;

  .change-password-button {
    width: 100%;
  }

  .cancel-change-password-form-button {
    width: 100%;
    margin-top: 10px;
  }
`;

interface ISubmitValues {
  password: string;
  repassword: string;
}

const ChangePasswordForm = (props: IFormProps) => {
  const onSubmit = async ({ password, repassword }: ISubmitValues) => {
    props.onSubmit();

    if (props.token == null) {
      return;
    }

    const result = await AccountProvider.changePasswordWithRecoveryToken(
      password,
      repassword,
      props.token,
    );

    if (isResponseError(result)) {
      return handleError(result);
    }

    showSuccessNotifcation(result.data.message);
    props.onChangeFormId(FormIds.Login);
  };

  const onCancel = () => {
    debugger;
    props.onChangeFormId(FormIds.Login);
  };

  return (
    <Wrapper>
      <Descriptions title="Change Password">
        <Descriptions.Item>
          To change your developer portal account password, please enter a new
          one and proceed by pressing the change password button.
        </Descriptions.Item>
      </Descriptions>
      <Form
        name="change-password-form"
        className="change-password-form"
        onFinish={(v: any) => onSubmit(v)}
      >
        <Form.Item
          name="password"
          rules={[
            { required: true, message: 'Please enter your new password.' },
          ]}
        >
          <Input type="password" placeholder="New password" />
        </Form.Item>
        <Form.Item
          name="repassword"
          rules={[
            { required: true, message: 'Please re-enter your new password.' },
          ]}
        >
          <Input type="password" placeholder="Confirm password" />
        </Form.Item>
        <Form.Item>
          <Button
            shape="round"
            type="primary"
            htmlType="submit"
            className="change-password-button"
          >
            Change Password
          </Button>
          <Button
            shape="round"
            type="ghost"
            htmlType="button"
            className="cancel-change-password-form-button"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Wrapper>
  );
};

export default ChangePasswordForm;
