import React, { useState, useEffect } from 'react';
import { Menu } from 'antd';
import { LockOutlined, LogoutOutlined, TeamOutlined } from '@ant-design/icons';

import { AccountProvider, SettingsProvider } from 'bc-providers';
import { Cookies, getTokenFromString } from 'bc-cookies';

import styles from './Account.module.less';
import { handleError, isResponseError } from '../utils';
import { IAccountSettingsData } from 'bc-models/dist/backend';

interface AccountProps {
  label: string;
  rootDomain: string;
  serviceApiUrl: string;
  language?: 'en';
}

export const Account: React.FC<AccountProps> = (props: AccountProps) => {
  const [settings, setSettings] = useState<IAccountSettingsData | null>(null);

  useEffect(() => {
    (window as any).appBaseServiceUrl = props.serviceApiUrl;

    const token = getTokenFromString(document.cookie);

    SettingsProvider.get(token).then((data) => {
      if (isResponseError(data)) {
        return handleError(data);
      }

      setSettings(data.data as IAccountSettingsData);
    });
  }, []);

  const { label } = props;

  const logout = async () => {
    const token = getTokenFromString(document.cookie);
    await AccountProvider.logout(token);

    document.cookie = `${Cookies.portal}=${token}`;
    window.location.href = '/';
  };

  return (
    <Menu mode="horizontal" className={styles.account} selectedKeys={[]}>
      <Menu.SubMenu className={styles.accountMenuItem} title={label}>
        {settings != null && settings.isAdmin === false ? null : (
          <Menu.Item key="1" icon={<TeamOutlined />}>
            <a href="/administration">Administration</a>
          </Menu.Item>
        )}
        <Menu.Item key="2" icon={<LockOutlined />}>
          <a href="/settings">Settings</a>
        </Menu.Item>
        <Menu.Item key="3" icon={<LogoutOutlined />} onClick={logout}>
          Logout
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );
};

export default Account;
