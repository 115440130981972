/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/jsx/jsx';
import 'codemirror/mode/htmlembedded/htmlembedded';
import 'codemirror/addon/selection/active-line';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/edit/matchtags';
import 'codemirror/addon/fold/foldcode';
import 'codemirror/addon/fold/foldgutter';
import 'codemirror/addon/fold/brace-fold';
import 'normalize.css/normalize.css';
import 'prism-themes/themes/prism-base16-ateliersulphurpool.light.css';
import 'prismjs/plugins/command-line/prism-command-line.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/mdn-like.css';
import 'codemirror/addon/fold/foldgutter.css';
import 'rc-drawer/assets/index.css';
import 'docsearch.js/dist/cdn/docsearch.min.css';
import './site/global.less';
import React from 'react';
import { Button, notification } from 'antd';
import insertCss from 'insert-css';

window.insertCss = insertCss;

// gatsby-browser.js
export const onServiceWorkerUpdateFound = () => {
  const lang = 'en';
  notification.info({
    message: 'Site Updated',
    description:
      'This site has been updated with new data. Do you wish to reload the site to get the new data?',
    btn: (
      <Button
        type="primary"
        size="small"
        onClick={() => window.location.reload(true)}
      >
        {'Refresh'}
      </Button>
    ),
    key: 'onServiceWorkerUpdateFound',
    placement: 'bottomRight',
  });
};
