import * as React from 'react';
import styled from 'styled-components';

import { AccountProvider } from 'bc-providers';

import { IFormProps, FormIds } from './Form';
import { Form, Input, Button, Descriptions } from 'antd';
import { isResponseError, handleError } from '../utils';

const Wrapper = styled.div`
  max-width: 300px;

  .login-form-forgot {
    float: right;
  }

  .ant-col-rtl .login-form-forgot {
    float: left;
  }

  .login-form-button {
    width: 100%;
  }

  .no-account-form-button {
    width: 100%;
    margin-top: 10px;
  }
`;

interface ISubmitValues {
  email: string;
  password: string;
}

export const setBlucecodeDeveloperSdkCookie = (token: string) => {
  document.cookie = `portal=${token};`;
};

const LoginForm = (props: IFormProps) => {
  const onSubmit = async ({ email, password }: ISubmitValues) => {
    props.onSubmit();
    const result = await AccountProvider.login(email, password);

    if (isResponseError(result)) {
      return handleError(result);
    }

    const data = result.data as any;

    setBlucecodeDeveloperSdkCookie(data.token);
    setTimeout(() => (window.location.href = data.redirect), 500);
  };

  const onDontHaveAnAccount = () => {
    props.onChangeFormId(FormIds.DontHaveAnAccount);
  };

  const onForgotPassword = () => {
    props.onChangeFormId(FormIds.ForgotPassword);
  };

  return (
    <Wrapper>
      <Descriptions title="Welcome to the Developer Portal">
        <Descriptions.Item>
          Please enter your email and password to access the Bluecode Developer
          Resources
        </Descriptions.Item>
      </Descriptions>
      <Form
        name="login-form"
        className="login-form"
        onFinish={(v: any) => onSubmit(v)}
      >
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Please enter your eMail.' }]}
        >
          <Input placeholder="eMail" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please enter your password.' }]}
        >
          <Input type="password" placeholder="Password" />
        </Form.Item>
        <Form.Item>
          <Button
            shape="round"
            type="link"
            htmlType="button"
            className="login-form-forgot"
            onClick={onForgotPassword}
          >
            Forgot password
          </Button>
        </Form.Item>

        <Form.Item>
          <Button
            shape="round"
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Log in
          </Button>
          <Button
            shape="round"
            type="ghost"
            htmlType="button"
            className="no-account-form-button"
            onClick={onDontHaveAnAccount}
          >
            Don't have an account?
          </Button>
        </Form.Item>
      </Form>
    </Wrapper>
  );
};

export default LoginForm;
