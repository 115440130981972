import * as React from 'react';
import styled from 'styled-components';

import { AccountProvider } from 'bc-providers';

import Header from '../components/Header';
import LoginForm from '../components/LoginForm';
import DontHaveAnAccountForm from '../components/DontHaveAnAccountForm';
import ForgotPasswordForm from '../components/ForgotPasswordForm';
import ChangePasswordForm from '../components/ChangePasswordForm';

import { Footer } from '../components/Footer';
import { LockerForm } from '../components/LockerForm';
import { LockerStates } from '../components/LockerComponent';
import { FormIds } from '../components/Form';

import {
  Notification,
  showSuccessNotifcation,
} from '../components/Notification';

import {
  isBrowser,
  serverSideRenderingStyles,
  isResponseError,
  handleError,
} from '../utils';

const Wrapper = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  min-height: 600px;
  min-width: 320px;
  background: linear-gradient(225deg, #ffffff, #f0f5ff);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .notification-dismiss {
    display: none;
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const FormWrapper = styled.div`
  background: white;
  border-radius: 20px;
  padding: 24px;
  border: 1px solid #f0f0f0;
`;

const getFormForId = (id: FormIds) => {
  switch (id) {
    case FormIds.DontHaveAnAccount:
      return DontHaveAnAccountForm;
    case FormIds.ForgotPassword:
      return ForgotPasswordForm;
    case FormIds.ChangePassword:
      return ChangePasswordForm;
    default:
      return LoginForm;
  }
};

const getValueFromUrlParams = (key: string) => {
  if (isBrowser === false) {
    return null;
  }

  const params = new URLSearchParams(window.location.search);
  const param = params.get(key);
  return param;
};

const handleActivationToken = async () => {
  const token = getValueFromUrlParams('token');
  if (token == null) {
    return;
  }

  const response = await AccountProvider.activate(token);
  if (isResponseError(response)) {
    return handleError(response);
  }

  showSuccessNotifcation(response.data.message);
};

const getChangePasswordToken = () => {
  const token = getValueFromUrlParams('password-reset-token');
  if (token == null) {
    return;
  }
  return token;
};

const getInitialFormId = () =>
  getChangePasswordToken() != null ? FormIds.ChangePassword : FormIds.Login;

export interface ILoginPageProps {
  serviceApiUrl: string;
  rootDomain: string;
}

export const LoginPage = (props: ILoginPageProps) => {
  const [windowWidth, setWindowWidth] = React.useState<number | null>(null);
  const [lockerState, setLockerState] = React.useState(LockerStates.close);
  const [currentFormId, setCurrentFormId] = React.useState(getInitialFormId());

  React.useEffect(() => {
    handleActivationToken();
    setLockerState(LockerStates.open);

    if (isBrowser === false) {
      return;
    }

    const resize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', resize, false);

    return () => window.removeEventListener('resize', resize, false);
  }, []);

  const CurrentForm = getFormForId(currentFormId);

  const handleChangeFormId = (id: FormIds) => setCurrentFormId(id);

  const handleFormSubmit = () => setLockerState(LockerStates.close);

  if (isBrowser === false) {
    return <div style={serverSideRenderingStyles()} />;
  }

  (window as any).appBaseServiceUrl = props.serviceApiUrl;

  return (
    <Wrapper>
      <Header />
      <ContentWrapper>
        {(windowWidth || window.innerWidth) < 600 ? (
          <FormWrapper>
            <CurrentForm
              token={getChangePasswordToken()}
              serviceProviderUrl={props.serviceApiUrl}
              onChangeFormId={handleChangeFormId}
              onSubmit={handleFormSubmit}
            />
          </FormWrapper>
        ) : (
          <LockerForm state={lockerState}>
            <CurrentForm
              token={getChangePasswordToken()}
              serviceProviderUrl={props.serviceApiUrl}
              onChangeFormId={handleChangeFormId}
              onSubmit={handleFormSubmit}
            />
          </LockerForm>
        )}
      </ContentWrapper>
      <Footer />
      <Notification />
    </Wrapper>
  );
};

export default LoginPage;
