import * as React from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';

import { TermsAndConditionsContent } from './TermsAndConditions';

const termsAndConditionModal = () =>
  Modal.info({
    width: '70%',
    title: 'Bluecode Developer Portal Agreement',
    content: <TermsAndConditionsContent />,
    okButtonProps: {
      shape: 'round',
    },
  });

const mailto = () => {
  const link = document.createElement('a');
  link.href = 'mailto:sdk@bluecode.com';
  link.click();
};

const Wrapper = styled.footer`
  position: relative;
  width: 100%;
  height: 38px;
  line-height: 38px;
  font-size: 12px;
  text-align: center;
  color: #6a7b8c;
  background-color: #e6ebf0;
`;

const ActionButton = styled.span`
  color: #2f80ff;
  transition: opacity 0.3s ease;
  opacity: 0.7;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

export const Footer = (props: any) => {
  return (
    <Wrapper {...props}>
      <span>Copyright © {new Date().getFullYear()} Bluecode</span>
      <span> | </span>
      <ActionButton onClick={termsAndConditionModal}>
        Terms and Conditions
      </ActionButton>
      <span> | </span>
      <ActionButton onClick={mailto}>Contact Us</ActionButton>
    </Wrapper>
  );
};
