import { BackendModels, FrontendModels } from 'bc-models';
import { notification } from 'antd';

type Status = 'responded' | 'error' | 'timeout';

export const isResponseError = (
  data: FrontendModels.IResponse<BackendModels.IMessage>,
) =>
  data.type === FrontendModels.ResponseType.error ||
  data.type === FrontendModels.ResponseType.notFound ||
  data.type === FrontendModels.ResponseType.unauthorised;

export const showSuccessNotification = (
  title: string,
  description?: string,
  onClick?: () => void,
) => {
  notification.success({
    message: title,
    description,
    onClick: () => onClick && onClick(),
  });
};

export const showErrorNotification = (
  title: string,
  description?: string,
  onClick?: () => void,
) => {
  notification.error({
    message: title,
    description,
    onClick: () => onClick && onClick(),
  });
};

export const handleError = (
  data: FrontendModels.IResponse<BackendModels.IMessage>,
) => {
  if (isResponseError(data) === false) {
    return;
  }
  const e = (data.data as BackendModels.IMessage) || {
    message: 'An error has occurred',
  };
  showErrorNotification(e.message);
};

export const isBrowser = typeof window !== `undefined`;

export const capitalize = (s: string) => {
  if (typeof s !== 'string') {
    return '';
  }
  return s.charAt(0).toUpperCase() + s.slice(1);
};
