import React, { useEffect } from 'react';
import { useStaticQuery, graphql, withPrefix, Link } from 'gatsby';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getCurrentLangKey } from 'ptz-i18n';
import { Helmet } from 'react-helmet';
import Seo from '../components/Seo';
import Header from '../components/Header';
import PageLoading from '../components/PageLoading';
import styles from './layout.module.less';
import Footer from '../components/Footer';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    initImmediate: false,
    fallbackLng: 'en',
    keySeparator: false,
    react: {
      useSuspense: false,
    },
  });

const lngs = ['en'];

interface LayoutProps {
  children: React.ReactElement<any>;
  location: Location;
  pageContext: any;
}

function parseNulltoUndefined<T>(value: T) {
  if (value === null) {
    return undefined;
  }
  return value;
}

const Layout: React.FC<LayoutProps> = ({ children, location }) => {
  // https://github.com/gatsbyjs/gatsby/issues/13867#issuecomment-489481343
  if (location.pathname.includes('offline-plugin-app-shell-fallback')) {
    return <PageLoading />;
  }
  const query = graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          siteUrl
          serviceApiUrl
          showSearch
          showProductsCard
          navs {
            slug
            title
            target
          }
          redirects {
            from
            to
          }
          versions
        }
      }
      locales {
        internal {
          content
        }
      }
    }
  `;
  const { site, locales } = useStaticQuery(query);
  const {
    siteMetadata: {
      title,
      navs = [],
      siteUrl,
      serviceApiUrl,
      showSearch,
      showProductsCard,
      redirects = [],
      versions,
    },
  } = site;
  let resources = {};
  try {
    resources = JSON.parse(locales.internal.content);
  } catch (e) {
    // empty
  }
  const pathPrefix = withPrefix('/').replace(/\/$/, '');
  const path = location.pathname.replace(pathPrefix, '');
  const currentLangKey = getCurrentLangKey(lngs, 'en', path);

  if (!i18n.options.lng || process.env.NODE_ENV === 'production') {
    i18n.init({
      lng: currentLangKey,
    });
  }

  if (!i18n.options.resources) {
    i18n.init({
      resources,
    });
  }

  useEffect(() => {
    if (i18n.language !== currentLangKey) {
      i18n.changeLanguage(currentLangKey);
    }
  }, [currentLangKey]);

  if (
    location.pathname === pathPrefix ||
    (children && children.type && (children as any).type.noLayout)
  ) {
    return children;
  }

  const getRediectUrl = () => {
    const list = redirects || [];
    for (let i = 0; i < list.length; i += 1) {
      const { from = '', to, keepUrl } = list[i] as {
        from: string | RegExp;
        to: string;
        keepUrl?: boolean;
      };

      if (
        location.pathname !== from &&
        !new RegExp(from).test(location.pathname)
      ) {
        return;
      }
      if (keepUrl && new RegExp(from).test(location.pathname)) {
        return location.pathname.replace(new RegExp(from), to);
      }

      return;
    }
  };

  const rediectUrl = getRediectUrl();
  const rootDomain = siteUrl;

  return (
    <>
      {rediectUrl && (
        <Helmet>
          <meta httpEquiv="refresh" content={`0;url=${rediectUrl}`} />
        </Helmet>
      )}
      <Seo
        title={siteUrl === location.origin ? '' : title}
        lang={i18n.language}
      />
      <Header
        subTitle={title || ''}
        path={path}
        navs={navs}
        serviceApiUrl={serviceApiUrl}
        rootDomain={rootDomain}
        transparent={true}
        isHomePage={true}
        showSearch={parseNulltoUndefined(showSearch)}
        showProductsCard={parseNulltoUndefined(showProductsCard)}
        versions={versions}
      />
      <main className={styles.main}>{children}</main>
      <Footer rootDomain={rootDomain} />
    </>
  );
};

export default Layout;
