import * as React from 'react';
import * as NotificationSystem from 'react-notification-system';

import { getActiveTheme } from './Theme';
import { isBrowser } from '../utils';

const setNotificationSystemRef = (ref: any) => {
  if (isBrowser === false) {
    return;
  }
  (window as any).notificationSystem = ref;
};

const getNotificationStyles = () => {
  const theme = getActiveTheme(null);
  return {
    NotificationItem: {
      DefaultStyle: {
        background: theme.color7,
        border: 'none',
        borderRadius: '5px',
        boxShadow: `1px 1px 10px ${theme.color14}`,
        fontSize: '15px',
      },
      error: { color: theme.color13 },
      success: { color: theme.color16 },
    },
  };
};

const showNotification = (message: string, level: 'success' | 'error') => {
  const notification = (window as any).notificationSystem;
  if (notification == null) {
    console.error('Make sure notification system is set');
    return;
  }
  notification.addNotification({
    message,
    level,
  });
};

export const showErrorNotifcation = (message: string) =>
  showNotification(message, 'error');

export const showSuccessNotifcation = (message: string) =>
  showNotification(message, 'success');

export const Notification = () => (
  <NotificationSystem.default
    ref={setNotificationSystemRef}
    style={getNotificationStyles()}
  />
);
