module.exports = [{
      plugin: require('../../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"portal","short_name":"portal","start_url":"/","background_color":"#004899","theme_color":"#004899","display":"minimal-ui","icon":"/home/runner/work/sdk-developer-portal/sdk-developer-portal/frontend/@antv/gatsby-theme-antv/site/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"944a5e5d605645b37d5857e962bddfce"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/runner/work/sdk-developer-portal/sdk-developer-portal/frontend/@antv/gatsby-theme-antv/site/layouts/layout.tsx"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#004899"},
    },{
      plugin: require('../../@antv/gatsby-theme-antv/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
