import * as React from 'react';
import styled from 'styled-components';

import { Form, Input, Button, Descriptions } from 'antd';
import { AccountProvider } from 'bc-providers';

import { handleError, isResponseError } from '../utils';
import { FormIds, IFormProps } from './Form';
import { showSuccessNotifcation } from './Notification';

const Wrapper = styled.div`
  max-width: 300px;

  .login-form-forgot {
    float: right;
  }

  .ant-col-rtl .login-form-forgot {
    float: left;
  }

  .signup-form-button {
    width: 100%;
  }

  .cancel-form-button {
    width: 100%;
    margin-top: 10px;
  }
`;

interface ISubmitValues {
  company: string;
  email: string;
  password: string;
  repassword: string;
}

const DontHaveAnAccountForm = (props: IFormProps) => {
  const onSubmit = async ({ company, email, password }: ISubmitValues) => {
    props.onSubmit();
    const result = await AccountProvider.register(company, email, password);

    if (isResponseError(result)) {
      return handleError(result);
    }

    showSuccessNotifcation(result.data.message);
    props.onChangeFormId(FormIds.Login);
  };

  const onCancel = () => {
    props.onChangeFormId(FormIds.Login);
  };

  return (
    <Wrapper>
      <Descriptions title="Register an account">
        <Descriptions.Item>
          Please fill out the form to register a new account.
        </Descriptions.Item>
      </Descriptions>
      <Form
        name="dont-have-an-account-form"
        className="dont-have-an-account-form"
        onFinish={(v: any) => onSubmit(v)}
      >
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Please enter an eMail.' }]}
        >
          <Input placeholder="eMail" />
        </Form.Item>
        <Form.Item
          name="company"
          rules={[{ required: true, message: 'Please enter a company name.' }]}
        >
          <Input placeholder="Company" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please enter a password.' }]}
        >
          <Input type="password" placeholder="Password" />
        </Form.Item>
        <Form.Item
          name="repassword"
          rules={[{ required: true, message: 'Please re-enter the password.' }]}
        >
          <Input type="password" placeholder="Repeat password" />
        </Form.Item>
        <Form.Item>
          <Button
            shape="round"
            type="primary"
            htmlType="submit"
            className="signup-form-button"
          >
            Sign up
          </Button>
          <Button
            shape="round"
            type="ghost"
            htmlType="button"
            className="cancel-form-button"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Wrapper>
  );
};

export default DontHaveAnAccountForm;
