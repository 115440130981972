export interface IColorTheme {
  color1: string;
  color2: string;
  color3: string;
  color4: string;
  color5: string;
  color6: string;
  color7: string;
  color8: string;
  color9: string;
  color10: string;
  color11: string;
  color12: string;
  color13: string;
  color14: string;
  color15: string;
  color16: string;
}

export const Light = {
  color1: '#FAFAFA',
  color2: '#99A5B1',
  color3: '#F4F4F4',
  color4: '#194A98',
  color5: '#123670',
  color6: '#5278B5',
  color7: '#ffffff',
  color8: '#CACACA',
  color9: '#E1E1E1',
  color10: '#E5F3FF',
  color11: '#2F80FF',
  color12: '#333333',
  color13: '#e74c3c',
  color14: 'rgba(0, 0, 0, 0.05)',
  color15: '#e74c3c',
  color16: '#2ecc71',
};

export const Dark = {
  color1: '#FAFAFA',
  color2: '#99A5B1',
  color3: '#F4F4F4',
  color4: '#194A98',
  color5: '#123670',
  color6: '#5278B5',
  color7: '#ffffff',
  color8: '#CACACA',
  color9: '#E1E1E1',
  color10: '#E5F3FF',
  color11: '#2F80FF',
  color12: '#333333',
  color13: '#e74c3c',
  color14: 'rgba(229, 227, 227, 0.8)',
  color15: '#e74c3c',
  color16: '#2ecc71',
};

export interface ITheme {
  light: IColorTheme;
  dark: IColorTheme;
}

export interface IThemeState extends ITheme {
  active: keyof ITheme;
}

export const Theme: IThemeState = {
  active: 'light',
  dark: Dark,
  light: Light,
};

export const getActiveTheme = (props: { theme: IThemeState } | null) =>
  props == null || props.theme == null
    ? Theme[Theme.active]
    : props.theme[props.theme.active];
