import * as React from 'react';
import * as styled from 'styled-components';

import { LockerStates, Locker } from './LockerComponent';
import { Theme } from './Theme';

const LockerFormWrapper = styled.default.div`
  position: relative;
  display: flex;
  border-radius: 20px;
  overflow: hidden;
  background: ${Theme.light.color7};
  box-shadow: -5px 5px 15px rgba(0, 0, 0, 0.1);
`;

const ContentWrapper = styled.default.div`
  position: relative;
  width: 320px;
  padding: 10px 28px;
  display: flex;
  flex-direction: column;
`;

const ChildrenWrapper = styled.default.div`
  position: relative;
  margin-top: 5%;
  padding: 0;
  flex: 1;

  .loader {
    right: -1px;
  }
`;

interface ILockerFromProps {
  state: LockerStates;
  children?: any;
}

export const LockerForm = ({ children, state }: ILockerFromProps) => (
  <styled.ThemeProvider theme={Theme}>
    <LockerFormWrapper>
      <Locker width="220px" height="464px" state={state} />
      <ContentWrapper>
        <ChildrenWrapper>{children}</ChildrenWrapper>
      </ContentWrapper>
    </LockerFormWrapper>
  </styled.ThemeProvider>
);
