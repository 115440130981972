import * as React from 'react';
import styled from 'styled-components';

import { Form, Input, Button, Descriptions } from 'antd';
import { FormIds, IFormProps } from './Form';

import { AccountProvider } from 'bc-providers';
import { showSuccessNotifcation } from './Notification';
import { isResponseError, handleError } from '../utils';

const Wrapper = styled.div`
  max-width: 300px;

  .reset-password-form-button {
    width: 100%;
  }

  .cancel-form-button {
    width: 100%;
    margin-top: 10px;
  }
`;

interface ISubmitValues {
  email: string;
}

const ForgotPasswordForm = (props: IFormProps) => {
  const onSubmit = async ({ email }: ISubmitValues) => {
    props.onSubmit();
    const result = await AccountProvider.recoverPassword(email);

    if (isResponseError(result)) {
      return handleError(result);
    }

    showSuccessNotifcation(result.data.message);
    props.onChangeFormId(FormIds.Login);
  };

  const onCancel = () => {
    props.onChangeFormId(FormIds.Login);
  };

  return (
    <Wrapper>
      <Descriptions title="Recover an account">
        <Descriptions.Item>
          Please enter your eMail to reset your password. If the entered eMail
          is associated with an valid account we will send you a mail in order
          to reset the password.
        </Descriptions.Item>
      </Descriptions>
      <Form
        name="forgot-password-form"
        className="forgot-password-form"
        onFinish={(v: any) => onSubmit(v)}
      >
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Please enter an eMail.' }]}
        >
          <Input placeholder="eMail" />
        </Form.Item>
        <Form.Item>
          <Button
            shape="round"
            type="primary"
            htmlType="submit"
            className="reset-password-form-button"
          >
            Reset password
          </Button>
          <Button
            shape="round"
            type="ghost"
            htmlType="button"
            className="cancel-form-button"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Wrapper>
  );
};

export default ForgotPasswordForm;
