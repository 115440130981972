import { BackendModels, FrontendModels } from 'bc-models';

import { showErrorNotifcation } from './components/Notification';

export const isResponseError = (
  data: FrontendModels.IResponse<BackendModels.IMessage>,
) =>
  data.type === FrontendModels.ResponseType.error ||
  data.type === FrontendModels.ResponseType.notFound ||
  data.type === FrontendModels.ResponseType.unauthorised;

export const handleError = (
  data: FrontendModels.IResponse<BackendModels.IMessage>,
) => {
  if (isResponseError(data) === false) {
    return;
  }
  const e = (data.data as BackendModels.IMessage) || {
    message: 'An error has occurred',
  };
  showErrorNotifcation(e.message);
};

export const isBrowser = typeof window !== `undefined`;

export const serverSideRenderingStyles = (): any => ({
  position: 'absolute',
  width: '100vw',
  minWidth: '320px',
  height: '100vh',
  minHeight: '600px',
  background: 'linear-gradient(225deg, #ffffff, #f0f5ff)',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
});
