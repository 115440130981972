export enum FormIds {
  ChangePassword,
  DontHaveAnAccount,
  ForgotPassword,
  Login,
}

export interface IFormProps {
  serviceProviderUrl: string;
  token?: string;
  onChangeFormId: (id: FormIds) => void;
  onSubmit: () => void;
}
