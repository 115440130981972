// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---antv-gatsby-theme-antv-site-templates-document-tsx": () => import("./../../../../@antv/gatsby-theme-antv/site/templates/document.tsx" /* webpackChunkName: "component---antv-gatsby-theme-antv-site-templates-document-tsx" */),
  "component---site-pages-401-tsx": () => import("./../../../site/pages/401.tsx" /* webpackChunkName: "component---site-pages-401-tsx" */),
  "component---site-pages-404-tsx": () => import("./../../../site/pages/404.tsx" /* webpackChunkName: "component---site-pages-404-tsx" */),
  "component---site-pages-administration-tsx": () => import("./../../../site/pages/administration.tsx" /* webpackChunkName: "component---site-pages-administration-tsx" */),
  "component---site-pages-index-tsx": () => import("./../../../site/pages/index.tsx" /* webpackChunkName: "component---site-pages-index-tsx" */),
  "component---site-pages-settings-tsx": () => import("./../../../site/pages/settings.tsx" /* webpackChunkName: "component---site-pages-settings-tsx" */),
  "component---site-pages-terms-and-conditions-tsx": () => import("./../../../site/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---site-pages-terms-and-conditions-tsx" */),
  "component---site-pages-welcome-tsx": () => import("./../../../site/pages/welcome.tsx" /* webpackChunkName: "component---site-pages-welcome-tsx" */)
}

