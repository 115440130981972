import React from 'react';

import LoginPage from '@auth/login/site/pages/Login';

import { useStaticQuery, graphql } from 'gatsby';

const pageQuery = () => {
  const query = graphql`
    query loginPageMetaData {
      site {
        siteMetadata {
          siteUrl
          serviceApiUrl
        }
      }
    }
  `;

  const { site } = useStaticQuery(query);

  if (site == null || site.siteMetadata == null) {
    throw new Error('Failed to fetch Graphql data for portal/pages/Index.tsx');
  }

  return site.siteMetadata;
};

const IndexPage = () => {
  const { serviceApiUrl, siteUrl } = pageQuery();

  return <LoginPage serviceApiUrl={serviceApiUrl} rootDomain={siteUrl} />;
};

IndexPage.noLayout = true;

export default IndexPage;
