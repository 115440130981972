import * as React from 'react';
import styled from 'styled-components';

import { getActiveTheme, IThemeState } from './Theme';

import HandleSVG from '../images/handle.svg';

interface IComponent {
  width: string;
  height?: string;
  theme: IThemeState;
}

const sharedComponentStyles = (props: IComponent) => `
  width: ${props.width};
  height: ${props.height};
`;

const sharedChildStyles = (props: IComponent) => `
  position: absolute;
  ${sharedComponentStyles(props)};
`;

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  ${(props: IComponent) => sharedComponentStyles(props)};
`;

const Background = styled.div`
  z-index: 1;
  ${(props: IComponent) =>
    sharedChildStyles(props) +
    `
    background: ${getActiveTheme(props).color4};
  `}
`;

const Internal = styled.div`
  top: 50%;
  ${(props: IComponent) =>
    sharedChildStyles(props) +
    `
    background: ${getActiveTheme(props).color5};
  `}
`;

const PagesWrapper = styled.div`
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 10px;
  right: 10px;
`;

const getPageMargin = (state: LockerStates) => {
  switch (state) {
    case LockerStates.standby || LockerStates.close:
      return '60%';
    case LockerStates.open:
      return '10%';
    default:
      console.log('no state');
      return '0%';
  }
};

interface IPage {
  state: LockerStates;
  theme: IThemeState;
}

const Pages = styled.div`
  ${(props: IPage) => `
    position: relative;
    width: 100%;
    height: 100%;
    transition: margin-top 0.5s ease;
    margin-top: ${getPageMargin(props.state)};
  
    & > div {
      width: 100%;
      min-height: 6px;
      height: 5%;
      background: ${getActiveTheme(props).color7};
    }

    & > div:first-child {
      opacity: 0.5;
    }

    & > div:nth-child(2) {
      opacity: 0.8;
    }

    & > div:last-child {
    height: 100%;
    }
  `}
`;

const getDrawerMargin = (state: LockerStates) => {
  switch (state) {
    case LockerStates.standby || LockerStates.close:
      return '0%';
    case LockerStates.open:
      return '30%';
    default:
      return '0%';
  }
};

const Drawer = styled.div`
  top: 50%;
  transition: margin-top 0.5s ease;
  ${(props: IComponent & { state: LockerStates }) =>
    sharedChildStyles(props) +
    `
    background: ${getActiveTheme(props).color4};
    margin-top: ${getDrawerMargin(props.state)};
    &::before {
      position: absolute;
      content: "";
      width: ${props.width};
      height: 10px;
      top: -6px;
      background: ${getActiveTheme(props).color6} 
    }
  `}
`;

const HandleWrapper = styled.div`
  width: 50%;
  margin: auto;
  margin-top: auto;
  margin-top: 15%;
`;

export enum LockerStates {
  standby,
  open,
  close,
}

interface ILockerProps {
  width: string;
  height: string;
  state: LockerStates;
}

export const Locker = ({ width, height, state }: ILockerProps) => (
  <Wrapper className="locker-wrapper" width={width} height={height}>
    <Background width={width} height="50%" />
    <Internal width={width} height="50%" />
    <PagesWrapper>
      <Pages state={state}>
        <div />
        <div />
        <div />
      </Pages>
    </PagesWrapper>
    <Drawer width={width} height="50%" state={state}>
      <HandleWrapper>
        <HandleSVG />
      </HandleWrapper>
    </Drawer>
  </Wrapper>
);
